$(document).ready(function () {
    $('#country').on('change', function () {
        var countryId = $(this).val();
        if (countryId) {
            $('#state').empty().append('<option>Loading...</option>').prop('disabled', true);
            $('#city').empty().append('<option>Select City</option>').prop('disabled', true);

            $.ajax({
                url: '/get-states/' + countryId,
                type: "GET",
                dataType: "json",
                success: function (data) {
                    $('#state').empty().prop('disabled', false);

                    if (data.length > 0) {
                        $('#state').append('<option value="">Select State</option>');
                        $.each(data, function (key, value) {
                            $('#state').append('<option value="' + value.id + '">' + value.name + '</option>');
                        });
                    } else {
                        $('#state').append('<option value="">No states available</option>').prop('disabled', true);
                    }
                },
                error: function () {
                    $('#state').empty().append('<option>Error loading states</option>').prop('disabled', true);
                }
            });
        } else {
            $('#state').empty().append('<option>Select State</option>').prop('disabled', true);
            $('#city').empty().append('<option>Select City</option>').prop('disabled', true);
        }
    });

    $('#state').on('change', function () {
        var stateId = $(this).val();
        if (stateId) {
            $('#city').empty().append('<option>Loading...</option>').prop('disabled', true);

            $.ajax({
                url: '/get-cities/' + stateId,
                type: "GET",
                dataType: "json",
                success: function (data) {
                    $('#city').empty().prop('disabled', false);

                    if (data.length > 0) {
                        $('#city').append('<option value="">Select City</option>');
                        $.each(data, function (key, value) {
                            $('#city').append('<option value="' + value.id + '">' + value.name + '</option>');
                        });
                    } else {
                        $('#city').append('<option value="">No cities available</option>').prop('disabled', true);
                    }
                },
                error: function () {
                    $('#city').empty().append('<option>Error loading cities</option>').prop('disabled', true);
                }
            });
        } else {
            $('#city').empty().append('<option>Select City</option>').prop('disabled', true);
        }
    });
});
$(document).ready(function () {
    $('#billing-details-form').on('submit', function (e) {
        if ($('#state').is(':disabled') || $('#state').val() === "") {
            alert('Please select a valid state.');
            e.preventDefault();
        } else if ($('#city').is(':disabled') || $('#city').val() === "") {
            alert('Please select a valid city.');
            e.preventDefault();
        }
    });
});