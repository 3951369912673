$(document).ready(function () {
  $("#language-select").on("change", function () {
    var selectedOption = $(this).find("option:selected");
    var flag = selectedOption.data("flag");
    var flagUrl = `https://flagsapi.com/${flag}/shiny/64.png`;

    $("#current-flag").attr("src", flagUrl);
  });
});

/* Sidebar */

$(document).ready(function () {
  $("#sidebarCollapse").on("click", function () {
    $("#sidebar").addClass("active");
    $(".overlay").addClass("visible");
  });

  $("#sidebarCollapseX").on("click", function () {
    $(".overlay").removeClass("visible");
    $("#sidebar").removeClass("active");
  });

  $(".overlay").on("click", function () {
    $("#sidebar").removeClass("active");
    $(".overlay").removeClass("visible");
  });
});

/* COLORS BTN */

const buttons = document.querySelectorAll(".colors .btn");

buttons.forEach((button) => {
  button.addEventListener("click", () => {
    buttons.forEach((btn) => btn.classList.remove("active"));
    button.classList.add("active");
  });
});

/* product quantity */

$("#decrement").click(function () {
  let currentQuantity = parseInt($("#quantity").val());
  if (currentQuantity > parseInt($("#quantity").attr("min"))) {
    $("#quantity").val(currentQuantity - 1);
  }
});

$("#increment").click(function () {
  let currentQuantity = parseInt($("#quantity").val());
  $("#quantity").val(currentQuantity + 1);
});

const activeImage = document.getElementById("active-image");
const zoomedImg = document.getElementById("zoomed-img");

const swiperSlides = document.querySelectorAll(".swiper-slide img");
swiperSlides.forEach((slide) => {
  slide.addEventListener("click", function () {
    activeImage.src = this.src;
    zoomedImg.src = this.src;
  });
});

$(".zoom")
  .on("mousemove", function (e) {
    const zoomer = $(this);
    const offsetX = e.offsetX || e.touches[0].pageX;
    const offsetY = e.offsetY || e.touches[0].pageY;
    const x = (offsetX / zoomer.width()) * 100;
    const y = (offsetY / zoomer.height()) * 100;
    zoomer.find("img").css("transform-origin", `${x}% ${y}%`);
    zoomer.find("img").css("transform", "scale(2)");
  })
  .on("mouseleave", function () {
    $(this).find("img").css("transform", "scale(1)");
  });

// filter by category
$("#select-category").on("change", function () {
  const selectedCategory = this.value;
  const productCards = document.querySelectorAll(".swiper-slide");

  productCards.forEach((card) => {
    if (
      selectedCategory === "all" ||
      card.dataset.category === selectedCategory
    ) {
      card.style.display = "block";
    } else {
      card.style.display = "none";
    }
  });
});

/* cart product quantity */

$(".cart-section").on("click", ".decrement", function () {
  let $cartCard = $(this).closest(".cart-card");
  let $quantity = $cartCard.find(".quantity");
  let currentQuantity = parseInt($quantity.text());
  let minQuantity = 1;

  if (currentQuantity > minQuantity) {
    currentQuantity--;
    $quantity.text(currentQuantity);
    updateTotal($cartCard, currentQuantity);
    updateOverallTotal();
  }
});

$(".cart-section").on("click", ".increment", function () {
  let $cartCard = $(this).closest(".cart-card");
  let $quantity = $cartCard.find(".quantity");
  let currentQuantity = parseInt($quantity.text());

  currentQuantity++;
  $quantity.text(currentQuantity);
  updateTotal($cartCard, currentQuantity);
  updateOverallTotal();
});

function updateTotal($cartCard, quantity) {
  let priceText = $cartCard.find(".price").text();
  let price = parseFloat(priceText.replace("£", ""));
  let total = price * quantity;

  $cartCard.find(".total").text("£" + total.toFixed(2));
}

function updateOverallTotal() {
  let overallTotal = 0;

  $(".cart-section .cart-card").each(function () {
    let totalText = $(this).find(".total").text();
    let total = parseFloat(totalText.replace("£", "").replace(",", ""));
    if (!isNaN(total)) {
      overallTotal += total;
    }
  });

  $(".total-price").text("£" + overallTotal.toFixed(2));
}

// Initial calculation of overall total
updateOverallTotal();

// Toggle for account details password
$("#toggle-password").on("click", function () {
  let passwordInput = $("#change-password");
  let icon = $(this);

  if (passwordInput.attr("type") === "password") {
    passwordInput.attr("type", "text");
    icon.removeClass("bx-hide").addClass("bx-show");
  } else {
    passwordInput.attr("type", "password");
    icon.removeClass("bx-show").addClass("bx-hide");
  }
});
