// Hero section swiper

let hero_swiper = new Swiper(".hero-section .mySwiper", {
  navigation: {
    nextEl: ".hero-section .swiper-button-next",
    prevEl: ".hero-section .swiper-button-prev",
  },
});

// Categories section swiper

let categories_swiper = new Swiper(".categories-section .mySwiper", {
  navigation: {
    nextEl: ".categories-section .swiper-button-next",
    prevEl: ".categories-section .swiper-button-prev",
  },
  breakpoints: {
    1024: {
      slidesPerView: 7,
      spaceBetween: 40,
    },
    768: {
      slidesPerView: 6,
      spaceBetween: 30,
    },
    640: {
      slidesPerView: 5,
      spaceBetween: 10,
    },
    320: {
      slidesPerView: 4,
      spaceBetween: 10,
    },
  },
});

// Recommended Products Section Swiper

let recommended_products_swiper = new Swiper(
  ".recommended-products-section .mySwiper",
  {
    navigation: {
      nextEl: ".recommended-products-section .swiper-button-next",
      prevEl: ".recommended-products-section .swiper-button-prev",
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      1004: {
        slidesPerView: 3,
      },
      986: {
        slidesPerView: 2,
      },
      1215: {
        slidesPerView: 4,
      },
    },
  }
);

// New Products section swiper

document.addEventListener("DOMContentLoaded", function () {
  const swiper = new Swiper(".new-products-section .swiper-container", {
    grid: {
      rows: 1,
      fill: "row",
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      986: {
        slidesPerView: 2,
      },
      1004: {
        slidesPerView: 3,
      },
      1215: {
        slidesPerView: 4,
      },
    },
    on: {
      init: function () {
        updatePagination(this);
      },
      slideChange: function () {
        updatePagination(this);
      },
    },
  });

  function updatePagination(swiperInstance) {
    const slidesPerView = swiperInstance.params.slidesPerView;
    const totalSlides = swiperInstance.slides.length;
    const slidesPerPage =
      slidesPerView * (swiperInstance.params.slidesPerColumn || 1);
    const totalPages = Math.ceil(totalSlides / slidesPerPage);
    const currentPage = Math.ceil(swiperInstance.realIndex / slidesPerPage) + 1;
    const paginationContainer = document.querySelector(
      ".new-products-section .custom-pagination-pages"
    );
    paginationContainer.innerHTML = "";

    // Previous Button
    document.querySelector(".new-products-section .prev-btn").disabled =
      currentPage === 1;
    document.querySelector(".new-products-section .next-btn").disabled =
      currentPage === totalPages;

    // Pages and Ellipsis
    let pagesHtml = "";
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - 1 && i <= currentPage + 1)
      ) {
        pagesHtml += `<span class="custom-pagination-page${
          i === currentPage ? " active-page" : ""
        }" data-page="${i}">${i}</span>`;
      } else if (i === currentPage - 2 || i === currentPage + 2) {
        pagesHtml += '<span class="ellipsis">...</span>';
      }
    }
    paginationContainer.innerHTML = pagesHtml;

    // Add Event Listeners to Pages
    document
      .querySelectorAll(".new-products-section .custom-pagination-page")
      .forEach((page) => {
        page.addEventListener("click", function () {
          const pageIndex = this.getAttribute("data-page") - 1;
          swiperInstance.slideTo(pageIndex * slidesPerPage);
        });
      });
  }

  // Add Event Listeners to Prev/Next Buttons
  $(".new-products-section .prev-btn").on("click", function () {
    swiper.slidePrev();
  });

  $(".new-products-section .next-btn").on("click", function () {
    swiper.slideNext();
  });
});

// Products page

document.addEventListener("DOMContentLoaded", function () {
  let swiper;

  function initializeSwiper(viewMode) {
    let slidesPerView = viewMode === "grid" ? 4 : 2;
    let breakpoints = {
      0: {
        slidesPerView: viewMode === "grid" ? 1 : 1,
        grid: {
          rows: 3,
          fill: "row",
        },
      },
      768: {
        slidesPerView: viewMode === "grid" ? 2 : 1,
        grid: {
          rows: 3,
          fill: "row",
        },
      },
      992: {
        slidesPerView: viewMode === "grid" ? 3 : 1,
        grid: {
          rows: 3,
          fill: "row",
        },
      },
      1200: {
        slidesPerView: viewMode === "grid" ? 4 : 2,
        grid: {
          rows: 3,
          fill: "row",
        },
      },
    };

    if (swiper) {
      swiper.destroy(true, true);
    }

    swiper = new Swiper(".products-section .swiper-container", {
      slidesPerView: slidesPerView,
      grid: {
        rows: 3,
        fill: "row",
      },
      on: {
        init: function () {
          updatePagination(this);
        },
        slideChange: function () {
          updatePagination(this);
        },
      },
      breakpoints: breakpoints,
    });
  }

  function updatePagination(swiperInstance) {
    const slidesPerView = swiperInstance.params.slidesPerView;
    const slidesPerColumn = swiperInstance.params.grid.rows || 1;
    const slidesPerPage = slidesPerView * slidesPerColumn;
    const totalSlides = swiperInstance.slides.length;
    const totalPages = Math.ceil(totalSlides / slidesPerPage);

    // Calculate the current page
    const currentPage =
      Math.floor(swiperInstance.activeIndex + 1 / slidesPerPage) + 1;

    const paginationContainer = document.querySelector(
      ".products-section .custom-pagination-pages"
    );
    paginationContainer.innerHTML = "";

    document.querySelector(".products-section .prev-btn").disabled =
      currentPage === 1;
    document.querySelector(".products-section .next-btn").disabled =
      currentPage === totalPages;

    let pagesHtml = "";
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - 1 && i <= currentPage + 1)
      ) {
        pagesHtml += `<span class="custom-pagination-page${
          i === currentPage ? " active-page" : ""
        }" data-page="${i}">${i}</span>`;
      } else if (i === currentPage - 2 || i === currentPage + 2) {
        pagesHtml += '<span class="ellipsis">...</span>';
      }
    }
    paginationContainer.innerHTML = pagesHtml;

    document
      .querySelectorAll(".products-section .custom-pagination-page")
      .forEach((page) => {
        page.addEventListener("click", function () {
          const pageIndex = this.getAttribute("data-page") - 1;
          swiperInstance.slideTo(pageIndex * slidesPerPage);
        });
      });
  }

  $(".products-section .prev-btn").on("click", function () {
    swiper.slidePrev();
  });

  $(".products-section .next-btn").on("click", function () {
    swiper.slideNext();
  });

  $(".grid-btn").on("click", function () {
    $(this).addClass("active");
    $(".list-btn").removeClass("active");
    $(".products-section .swiper-wrapper").removeClass("list").addClass("grid");
    initializeSwiper("grid");
  });

  $(".list-btn").on("click", function () {
    $(this).addClass("active");
    $(".grid-btn").removeClass("active");
    $(".products-section .swiper-wrapper").removeClass("grid").addClass("list");
    initializeSwiper("list");
  });

  initializeSwiper("grid");
});

// Related Products Section Swiper

let related_products_swiper = new Swiper(
  ".related-products-section .mySwiper",
  {
    navigation: {
      nextEl: ".related-products-section .swiper-button-next",
      prevEl: ".related-products-section .swiper-button-prev",
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      1004: {
        slidesPerView: 3,
      },
      986: {
        slidesPerView: 2,
      },
      1215: {
        slidesPerView: 4,
      },
    },
  }
);

// product details

let thumbs = new Swiper(".product-details-section .mySwiper", {
  navigation: {
    nextEl: ".product-details-section .swiper-button-next",
    prevEl: ".product-details-section .swiper-button-prev",
  },
  slidesPerView: 5,
  spaceBetween: 4,
});

// Tech Talk Blog Page - Posts Swiper

document.addEventListener("DOMContentLoaded", function () {
  const swiper = new Swiper(".blog-section .swiper-container", {
    grid: {
      rows: 4,
      fill: "row",
    },
    spaceBetween: 15,
    breakpoints: {
      0: {
        slidesPerView: 1,
        grid: {
          rows: 4,
          fill: "row",
        },
      },
      986: {
        slidesPerView: 1,
        grid: {
          rows: 4,
          fill: "row",
        },
      },
      1004: {
        slidesPerView: 2,
        grid: {
          rows: 4,
          fill: "row",
        },
      },
      1215: {
        slidesPerView: 2,
        grid: {
          rows: 4,
          fill: "row",
        },
      },
    },
    on: {
      init: function () {
        updatePagination(this);
      },
      slideChange: function () {
        updatePagination(this);
      },
    },
  });

  function updatePagination(swiperInstance) {
    const slidesPerView = swiperInstance.params.slidesPerView;
    const slidesPerColumn = swiperInstance.params.grid.rows || 1;
    const slidesPerPage = slidesPerView * slidesPerColumn;
    const totalSlides = swiperInstance.slides.length;
    const totalPages = Math.ceil(totalSlides / slidesPerPage);

    // Calculate the current page
    const currentPage =
      Math.floor(swiperInstance.activeIndex + 1 / slidesPerPage) + 1;

    const paginationContainer = document.querySelector(
      ".blog-section .custom-pagination-pages"
    );
    paginationContainer.innerHTML = "";

    // Previous Button
    document.querySelector(".blog-section .prev-btn").disabled =
      currentPage === 1;
    document.querySelector(".blog-section .next-btn").disabled =
      currentPage === totalPages;

    // Pages and Ellipsis
    let pagesHtml = "";
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - 1 && i <= currentPage + 1)
      ) {
        pagesHtml += `<span class="custom-pagination-page${
          i === currentPage ? " active-page" : ""
        }" data-page="${i}">${i}</span>`;
      } else if (i === currentPage - 2 || i === currentPage + 2) {
        pagesHtml += '<span class="ellipsis">...</span>';
      }
    }
    paginationContainer.innerHTML = pagesHtml;

    // Add Event Listeners to Pages
    document
      .querySelectorAll(".blog-section .custom-pagination-page")
      .forEach((page) => {
        page.addEventListener("click", function () {
          const pageIndex = this.getAttribute("data-page") - 1;
          swiperInstance.slideTo(pageIndex * slidesPerPage);
        });
      });
  }

  // Add Event Listeners to Prev/Next Buttons
  $(".blog-section .prev-btn").on("click", function () {
    swiper.slidePrev();
  });

  $(".blog-section .next-btn").on("click", function () {
    swiper.slideNext();
  });
});

//frequently asked questions - Q&A

document.addEventListener("DOMContentLoaded", function () {
  const swiper = new Swiper("#Q-A-part .swiper-container", {
    navigation: {
      nextEl: "#Q-A-part .next-btn",
      prevEl: "#Q-A-part .prev-btn",
    },
    slidesPerView: 1,
    grid: {
      rows: 4,
      fill: "row",
    },
    spaceBetween: 15,
  });
});

// frequently asked questions - related articles

document.addEventListener("DOMContentLoaded", function () {
  const swiper = new Swiper(".related-articles-section .swiper-container", {
    grid: {
      rows: 1,
      fill: "row",
    },
    spaceBetween: 15,
    breakpoints: {
      0: {
        slidesPerView: 1,
        grid: {
          rows: 1,
          fill: "row",
        },
      },
      986: {
        slidesPerView: 2,
        grid: {
          rows: 1,
          fill: "row",
        },
      },
      1004: {
        slidesPerView: 3,
        grid: {
          rows: 1,
          fill: "row",
        },
      },
    },
  });
});
