document.addEventListener('DOMContentLoaded', () => {
    // Registeration

    const signUpBtnLink = document.querySelector(".SignUpBtn-link");
    const signInBtnLink = document.querySelector(".SignInBtn-link");
    const wrapper = document.querySelector(".wrapper");

    if (signUpBtnLink && signInBtnLink && wrapper) {
        signUpBtnLink.addEventListener("click", () => {
            wrapper.classList.toggle("active");
        });

        signInBtnLink.addEventListener("click", () => {
            wrapper.classList.toggle("active");
        });
    }

    // Toggle for Sign-Up password
    $("#toggle-signup-password").on("click", function () {
        let passwordInput = $("#signup-password");
        let icon = $(this);

        if (passwordInput.attr("type") === "password") {
            passwordInput.attr("type", "text");
            icon.removeClass("bx-hide").addClass("bx-show");
        } else {
            passwordInput.attr("type", "password");
            icon.removeClass("bx-show").addClass("bx-hide");
        }
    });

    // Toggle for Sign-In password
    $("#toggle-signin-password").on("click", function () {
        let passwordInput = $("#signin-password");
        let icon = $(this);

        if (passwordInput.attr("type") === "password") {
            passwordInput.attr("type", "text");
            icon.removeClass("bx-hide").addClass("bx-show");
        } else {
            passwordInput.attr("type", "password");
            icon.removeClass("bx-show").addClass("bx-hide");
        }
    });

    // Initialize form validation
    (function () {
        "use strict";
        var form = document.getElementById("signin-form");

        if (form) {
            form.addEventListener(
                "submit",
                function (event) {
                    if (!form.checkValidity()) {
                        event.preventDefault();
                        event.stopPropagation();
                    }

                    form.classList.add("was-validated");
                },
                false
            );
        }
    })();

    function validateRecaptcha() {
        var recaptchaResponse = grecaptcha.getResponse();
        var recaptchaError = document.getElementById("recaptcha-error");

        if (recaptchaResponse.length === 0) {
            $("#recaptcha-error").show();
            return false;
        } else {
            $("#recaptcha-error").hide();
            return true;
        }
    }

    // Initialize form validation
    (function () {
        "use strict";
        var form = document.getElementById("signup-form");

        if (form) {
            form.addEventListener(
                "submit",
                function (event) {
                    var isRecaptchaValid = validateRecaptcha();

                    if (!form.checkValidity() || !isRecaptchaValid) {
                        event.preventDefault();
                        event.stopPropagation();
                    }

                    form.classList.add("was-validated");
                },
                false
            );
        }
    })();
});
