import 'bootstrap';
import '@popperjs/core';
import './custom/actions.js';
// import './custom/checkout.js';
// import './custom/faq.js';
import './custom/registration.js';
import './custom/swiper.js';

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();
